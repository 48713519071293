const GenericTableHeader = ({
  heading,
  subHeading,
  count,
  className,
  currencytype,
}: {
  heading: string;
  subHeading: string;
  count?: number;
  className?: string;
  currencytype?: string;
}) => (
  <div className={`flex flex-col px-6 lg:justify-start ${className}`}>
    <p className="md:text-lg text-base font-medium text-headerColor whitespace-nowrap">
      {heading}
    </p>
    <p className="mr-4 text-xs1 font-medium text-descriptionColor whitespace-nowrap md:mr-12">
      {count
        ? `${count.toLocaleString(currencytype)} ${subHeading}`
        : `${subHeading}`}
    </p>
  </div>
);

export default GenericTableHeader;
